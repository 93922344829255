import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { TopHeading } from '../components/heading';

class ImprintPage extends React.Component {
    render() {
        const { data } = this.props;
        const siteTitle = data.site.siteMetadata.title;

        return (
            <Layout
                location={this.props.location}
                title={siteTitle}
                lang="en"
                hasGerman={true}
                slug={this.props.path}
            >
                <SEO title="Legal Notice" />
                <TopHeading>Legal Notice</TopHeading>
                <article>
                    <p>
                        Because I live in Germany and this site is hostes in
                        Germany, I'm required to provied some legal notices.
                        Because I also provide them in german, this is just a
                        mere translation of the most important informations.
                        Please referr to the german version for all details.
                    </p>
                    <h2>Informations required by &sect; 5 TMG</h2>
                    <p>
                        Nicolas Hollmann
                        <br />
                        Lärchenkamp 4<br />
                        23847 Kastorf
                    </p>

                    <h2>Contact</h2>
                    <p>
                        Telephone: 0176 61554650
                        <br />
                        E-Mail: nico@hokli-software.de
                    </p>

                    <h3>Liability of Content</h3>
                    <p>
                        As the service provider we're according to &sect; 7
                        Abs.1 TMG responible of own content on this site.
                        According to &sect;&sect; 8 to 10 TMG we aren't required
                        to observe transmitted or saved external informations to
                        look after law violations.
                    </p>

                    <h3>Liability of Links</h3>
                    <p>
                        Our service contains links to external third party
                        websites where we can't control the content. Because of
                        this we can't be hold liable for violations on this
                        external content. At the time of linking the extenral
                        site was checked for unlawful content and nothing was
                        found.
                    </p>
                    <p>
                        A permanent control of external content is just not
                        reasonable. If we get noticed of law violations on
                        external content we will remove the link as fast as
                        possible.
                    </p>

                    <h3>Copyright</h3>
                    <p>
                        The contents created by the author of this site are
                        protected by the german copyright. Duplications, editing
                        or sharing that are not covered by the copyright require
                        the written acceptance of the author. Downloads or
                        copies are allowed for private, non-commercial usage.
                        The usage of the contents on this site by schools or
                        universities ist expressly <em>permitted</em>.
                    </p>
                    <p>
                        In case the content was not created by the author of
                        this site, copyright claims of third parties may apply.
                        Contents that are not by the author of this site will be
                        marked accordingly.
                    </p>

                    <p>
                        Source (german):{' '}
                        <a
                            href="https://www.e-recht24.de"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://www.e-recht24.de
                        </a>
                    </p>
                </article>
            </Layout>
        );
    }
}

export default ImprintPage;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
